import {
  Tooltip,
  CircularProgress,
  Button,
  MenuItem,
  Menu,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import botCircle from "../assets/images/finchatGPT/TradeLogo.png";
import userCircle from "../assets/images/finchatGPT/userCircle.png";
import TypingAnimation from "./TypingAnimation";
import ReactMarkdown from "react-markdown";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Highcharts from "highcharts";
import HighchartsStock from "highcharts/modules/stock";
import DragPanes from "highcharts/modules/drag-panes";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced";
import PriceIndicator from "highcharts/modules/price-indicator";
import FullScreen from "highcharts/modules/full-screen";
import StockTools from "highcharts/modules/stock-tools";
import HighchartsReact from "highcharts-react-official";
import lineChartOptions from "../assets/JSON/lineChart.json";
import barChartOptions from "../assets/JSON/barChart.json";
import _ from "lodash";
import gfm from "remark-gfm";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
// import ReplayIcon from '@mui/icons-material/Replay';
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import classNames from "classnames";
import depthChartOptions from "../assets/JSON/depthChartOptions.json";
import { useSelector } from "react-redux";
import stockChartLight from "../assets/JSON/stockChartOptionsLight.json";
import stockChartDark from "../assets/JSON/stockChartOptionsDark.json";
import { RootState } from "../assets/interfaces/interfaces";
import { getAuth } from "firebase/auth";
import { useEffect, useMemo, useState } from "react";
import { formatNumericValue, getMessageOutputAndLoading, getReducedLoadersArray } from "../helpers";
import rehypeRaw from "rehype-raw";
import ToolLoader from "./ToolLoader";
import { ReactComponent as ChevronDown } from "../assets/icons/ChevronDown.svg";
import { ReactComponent as PlusFilledCircle } from "../assets/icons/PlusFilledCircle.svg";
import { ReactComponent as CheckFilledCircle } from "../assets/icons/CheckFilledCircle.svg";
import {
  addTicker,
  setWatchlistDrawerState,
} from "../redux/slices/watchlistSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { useIncrementTrigger } from "./Watchlist/hooks";
import NewsSummary from "./ChatMessage/NewsSummary";
import { useParams } from "react-router-dom";

DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);
HighchartsStock(Highcharts);
const renderers = {
  a: ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ),
};

// check for any latex syntax
const containsLaTeX = text => /\\\[|\\\(|```latex/.test(text);
function ChatMessage({
  message,
  loading = false,
  showActions = false,
  onCopy,
  onLike,
  onDislike,
  isPreview = false,
  onScrollRequest,
  handleSendMessage,
}: {
  message;
  loading?: boolean;
  showActions?: boolean;
  onCopy?: (string) => void;
  onLike?: () => void;
  onDislike?: () => void;
  isPreview?: boolean;
  onScrollRequest?: () => void;
  handleSendMessage?: (string) => void;
}) {
  const isDarkMode = useSelector((state: { theme }) => state.theme.darkMode);
  const auth = getAuth();
  const isUserSignedIn = useMemo(() => {
    return !!auth?.currentUser;
  }, [auth?.currentUser]);

  const user = useSelector((state: RootState) => state.auth.currentUser);
  const { updateBadgeCount } = useIncrementTrigger();
  const dispatch = useDispatch<AppDispatch>();
  const userAvatar = useMemo(() => {
    if (user?.photoURL) return user.photoURL;
    let hash;
    let gravatarType = "identicon";
    if (!isUserSignedIn || !user?.email) {
      hash = "any";
      gravatarType = "mp";
    }
    else {
      const encoder = new TextEncoder();
      const data = encoder.encode(user.email);
      hash = crypto.subtle.digest("SHA-256", data);
    }
    return `https://www.gravatar.com/avatar/${hash}?s=200&d=${gravatarType}`;
  }, [user?.email, user?.photoURL, isUserSignedIn]);

  const getAvatarSrc = () => {
    if (message.isBot) {
      return botCircle;
    }
    if (isPreview) {
      return userCircle;
    }
    if (!loading && userAvatar) {
      return userAvatar;
    }
    return userCircle;
  };

  const { session_id } = useParams();
  // need immediate access to chat id to render summary cards instantly
  const finchatMessage = useSelector(
    (state: RootState) => state?.finchat?.finchatMessages?.[session_id],
  );

  const {
    latestSummaryFromLLM,
  } = useSelector((state: { newsSummary }) => state.newsSummary);

  const chatId = finchatMessage?.chat_id;
  const latestSummaryInSession = useMemo(() => latestSummaryFromLLM?.[session_id], [latestSummaryFromLLM, session_id]);

  const newsSummary = useMemo(() => {
    if (!latestSummaryInSession || !session_id) {
      return null;
    }
    const validChatId = loading ? chatId : message?.chat_id;

    if (latestSummaryInSession.chat_id === validChatId) {
      return latestSummaryInSession.news_summary;
    }

    return null;
  }, [session_id, chatId, message?.chat_id, latestSummaryInSession]);

  useEffect(() => {
    if (newsSummary && onScrollRequest) {
      onScrollRequest();
    }
  }, [newsSummary]);

  const tickersList = message.list_of_tickers || [];
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuWidth, setMenuWidth] = useState(null);
  const { tickers } = useSelector((state: { watchlist }) => state.watchlist);
  const [addedTickers, setAddedTickers] = useState(
    Array(tickersList.length).fill(false),
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleAddToWatchlistBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuWidth(event.currentTarget.clientWidth);
  };

  const handleAddToWatchlistBtnClose = () => {
    setAnchorEl(null);
  };

  // if extracted tickers is already in list, mark it
  useEffect(() => {
    if (!tickersList?.length) return;

    const updatedAddedTickers = tickersList.map(
      ticker => tickers.some(existingTicker => existingTicker?.ticker === ticker),
    );

    setAddedTickers(updatedAddedTickers);
  }, [tickersList, tickers]);

  const addTickerToWatchlist = async (index) => {
    const tickerToAdd = tickersList[index];
    if (addedTickers[index]) return;

    const setTickerState = (index, isAdded) => {
      setAddedTickers((prevTickers) => {
        const updatedTickers = [...prevTickers];
        updatedTickers[index] = isAdded;
        return updatedTickers;
      });
    };
    // add ticker optimistically
    setTickerState(index, true);
    try {
      await dispatch(addTicker({ userId: user?.userId, ticker: tickerToAdd })).unwrap();
    }
    catch (error) {
      console.error("error", error);
      // uncheck ticker if addition fails
      setTickerState(index, false);
    }
    finally {
      dispatch(setWatchlistDrawerState(true));
      updateBadgeCount();
      if (isMobile) {
        handleAddToWatchlistBtnClose();
      }
    }
  };

  const renderContent = (output) => {
    if (containsLaTeX(output)) {
      return <TypingAnimation text={output} components={renderers} />;
    }
    else {
      return (
        <ReactMarkdown
          remarkPlugins={[gfm]}
          rehypePlugins={[rehypeRaw]}
          components={renderers}
          className="markdown-body"
        >
          {output}
        </ReactMarkdown>
      );
    }
  };

  const [loadersArray, actualMessageOutput] = useMemo(() => getMessageOutputAndLoading(message.output), [message.output]);
  const loadersReducedArr = getReducedLoadersArray(loadersArray);

  return (
    <>
      <div className="w-full text-token-text-primary">
        <div className="px-4 py-2 justify-center text-base md:gap-6 m-auto">
          <div className="flex flex-1 text-base mx-auto gap-3 min-w-[432px] max-w-[768px] group">
            <div className="flex-shrink-0 flex flex-col relative items-end">
              <div>
                <div className="pt-0.5">
                  <div className="gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full">
                    <div className="relative flex">
                      <img
                        src={getAvatarSrc()}
                        alt={message.isBot ? "Bot" : "User"}
                        className="w-6 h-6 rounded-sm"
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative flex w-full flex-col">
              <div className="font-semibold select-none">
                {!message.isBot && !loading && !isPreview
                  ? "You"
                  : !message.isBot && isPreview
                      ? "User"
                      : "TradeGPT"}
              </div>
              <div className="flex-col gap-1 md:gap-3">
                <div className="flex flex-grow flex-col max-w-full gap-y-4">
                  <div
                    data-message-author-role="user"
                    className="min-h-[20px] overflow-y-hidden break-words overflow-x-auto flex flex-col gap-3"
                  >
                    <div>
                      {(loading || message.isBot) && !isPreview && newsSummary && <NewsSummary news={newsSummary} query={message?.user_query} handleSendMessage={handleSendMessage} />}
                      {!loading
                        ? (
                            <>
                              <ToolLoader key={`${message.outputLoading}`} loadersArray={loadersReducedArr} messageIsLoading={message.outputLoading} />
                              {renderContent(actualMessageOutput)}
                            </>
                          )
                        : (
                            <div className="flex items-center space-x-2 mt-1">
                              <CircularProgress size={16} disableShrink color="inherit" className="text-tgpt-secondary-main" />
                              <span className="text-sm text-token-text-secondary">
                                Analyzing ...
                              </span>
                            </div>
                          )}
                    </div>
                    {!!message.chart_flag
                    && message?.chart_data?.map((item, chartIndex) => {
                      const { chart_type } = item;
                      if (chart_type === "line-depth") {
                        const {
                          data_below = [],
                          data_above = [],
                          current_price = 0,
                          title = "Default Title",
                        } = item;
                        const options = _.cloneDeep(depthChartOptions);
                        options.series = [
                          {
                            name: "Puts",
                            data: [...data_below],
                            color: "#fc5857",
                          },
                          {
                            name: "Calls",
                            data: [...data_above],
                            color: "#03a7a8",
                          },
                        ];
                        options.xAxis.plotLines = [
                          {
                            color: "#888",
                            value: current_price,
                            width: 1,
                            label: {
                              text: "Actual price",
                              rotation: 90,
                            },
                          },
                        ];
                        options.title.text = title;
                        return (
                          <div
                            key={`${chartIndex}-depth-chart`}
                            id={`depthChartContainer-${chartIndex}`}
                            style={{ width: "100%", height: "400px" }}
                          >
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={options}
                            />
                          </div>
                        );
                      }
                      if (chart_type === "bar-2") {
                        const {
                          categories = [],
                          data = {},
                          mainTitle = "Default Main Title",
                          xAxisTitle = "X-Axis Title",
                          yAxisTitle = "Y-Axis Title",
                        } = item;
                        const options = JSON.parse(
                          JSON.stringify(barChartOptions),
                        );

                        options.title.text = mainTitle;
                        options.xAxis.categories = categories;
                        options.xAxis.title.text = xAxisTitle;
                        options.yAxis.title.text = yAxisTitle;

                        // Transform the data to fit Highcharts format
                        options.series = Object.keys(data).map(key => ({
                          name: key,
                          data: _.cloneDeep(data[key] || []), // Deep copy if data structure is complex
                        }));

                        return (
                          <div
                            className="barz"
                            id="highcharts-container"
                            key={chartIndex}
                          >
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={options}
                            />
                          </div>
                        );
                      }
                      if (chart_type === "price-candle") {
                        const {
                          chart_title = "Default Chart Title",
                          data = {},
                        } = item;
                        const options = isDarkMode
                          ? JSON.parse(JSON.stringify(stockChartDark))
                          : JSON.parse(JSON.stringify(stockChartLight));
                        const series = [];

                        Object.keys(data).forEach((ticker) => {
                          const tickerData = (data[ticker] || []).map(d => [
                            d[0],
                            d[1],
                            d[2],
                            d[3],
                            d[4],
                          ]);
                          series.push({
                            type: "candlestick",
                            name: ticker,
                            data: tickerData,
                            tooltip: {
                              valueDecimals: 2,
                            },
                          });
                        });

                        options.title.text = chart_title;
                        options.series = series;

                        return (
                          <div
                            key={`highstock-${chart_title}`}
                            id="highcharts-container"
                          >
                            <HighchartsReact
                              key={chartIndex}
                              highcharts={Highcharts}
                              constructorType="stockChart"
                              options={options}
                            />
                          </div>
                        );
                      }
                      if (chart_type === "price-line") {
                        const {
                          chart_title = "Default Chart Title",
                          data = {},
                          ticker = [],
                        } = item;
                        const options = JSON.parse(
                          JSON.stringify(lineChartOptions),
                        );
                        const series = [];

                        ticker.forEach((tickerName) => {
                          const seriesData = (data[tickerName] || []).map(
                            point => [...point],
                          );

                          const seriesObj = {
                            type: "line",
                            name: tickerName,
                            data: seriesData,
                          };
                          series.push(seriesObj);
                        });

                        if (series.length > 1) {
                          options.plotOptions.series = {
                            compare: "percent",
                          };
                          options.tooltip = {
                            shared: true,
                            split: true,
                            crosshairs: true,
                            pointFormatter: function () {
                              const change = this.change;
                              return (
                                "<span style=\"color:"
                                + this.series.color
                                + "\">"
                                + this.series.name
                                + "</span>: <b>"
                                + Highcharts.numberFormat(change, 2, ".")
                                + "%</b><br/>"
                              );
                            },
                            valueDecimals: 2,
                            backgroundColor: "rgb(0 54 143)",
                            borderColor: "rgb(0 54 143)",
                            style: {
                              color: "#FFF",
                              fontSize: "12px",
                            },
                          };
                        }

                        options.title.text = chart_title;
                        options.series = series;
                        options.yAxis.title.text = "Price";
                        options.xAxis.title = {
                          text: "Date",
                        };

                        return (
                          <div key={chartIndex} id="highcharts-container">
                            <HighchartsReact
                              key={chartIndex}
                              highcharts={Highcharts}
                              constructorType="stockChart"
                              options={options}
                            />
                          </div>
                        );
                      }
                      if (chart_type === "line") {
                        const {
                          chart_title = "Default Chart Title",
                          data = {},
                          yAxisTitle = "Y-Axis Title",
                          xAxisTitle = "X-Axis Title",
                          keys = [],
                        } = item;
                        const options = JSON.parse(
                          JSON.stringify(lineChartOptions),
                        );
                        const allSeries = Object.keys(data);
                        const series = [];

                        allSeries.forEach((seriesName) => {
                          const seriesData = (data[seriesName] || []).map(
                            (value, index) => {
                              return [
                                new Date(keys[index] || Date.now()).getTime(),
                                value,
                              ];
                            },
                          );

                          const seriesObj = {
                            type: "line",
                            name: seriesName,
                            data: seriesData,
                          };
                          series.push(seriesObj);
                        });

                        options.title.text = chart_title || "Default Title";
                        options.series = series;
                        options.yAxis.title.text
                            = yAxisTitle || "Y-Axis Title";
                        options.xAxis.title = {
                          text: xAxisTitle || "X-Axis Title",
                        };

                        if (series.length > 1) {
                          options.plotOptions.series = {
                            compare: "percent",
                          };
                          options.tooltip = {
                            shared: true,
                            split: true,
                            crosshairs: true,
                            pointFormatter: function () {
                              const change = this.change;
                              return (
                                "<span style=\"color:"
                                + this.series.color
                                + "\">"
                                + this.series.name
                                + "</span>: <b>"
                                + Highcharts.numberFormat(change, 2, ".")
                                + "%</b><br/>"
                              );
                            },
                            valueDecimals: 2,
                            backgroundColor: "rgb(0 54 143)",
                            borderColor: "rgb(0 54 143)",
                            style: {
                              color: "#FFF",
                              fontSize: "12px",
                            },
                          };
                        }

                        return (
                          <div key={chartIndex} id="highcharts-container">
                            <HighchartsReact
                              key={chartIndex}
                              highcharts={Highcharts}
                              constructorType="stockChart"
                              options={options}
                            />
                          </div>
                        );
                      }
                      if (chart_type === "bar") {
                        const {
                          chart_title = "Default Chart Title",
                          data = {},
                          xAxisTitle = "X-Axis Title",
                          yAxisTitle = "Y-Axis Title",
                          keys = [],
                        } = item;
                        const options = JSON.parse(
                          JSON.stringify(barChartOptions),
                        );
                          // Initialize an empty array for series
                        const seriesData = [];
                        // Iterate over each key in the data object
                        for (const key in data) {
                          if (
                            Object.prototype.hasOwnProperty.call(data, key)
                          ) {
                            seriesData.push({
                              name: key,
                              data: data[key] || [],
                            });
                          }
                        }
                        options.title.text = chart_title;
                        options.xAxis.categories = keys;
                        options.xAxis.title.text = xAxisTitle;
                        options.yAxis.title.text = yAxisTitle;
                        options.series = seriesData; // Assign the prepared series data
                        options.tooltip.formatter = function () {
                          return `<b>${this.x}</b><br/><span style="color:${
                            this.series.color
                          }">${
                            this.series.name
                          }</span>: <b>${Highcharts.numberFormat(
                            this.y,
                            0,
                          )}</b>`;
                        };
                        return (
                          <div
                            key={chart_title}
                            id="highcharts-container"
                            className="barz"
                          >
                            <HighchartsReact
                              key={chart_title}
                              highcharts={Highcharts}
                              constructorType="chart"
                              options={options}
                            />
                          </div>
                        );
                      }
                      if (chart_type === "table") {
                        const { headers = [], rows = [] } = item;

                        const rowData = rows.map((arr, index: number) => {
                          const obj = { id: index };
                          arr.forEach((item, key: number) => {
                            obj[key] = item;
                          });
                          return obj;
                        });

                        const columns: GridColDef[] = headers.map(
                          (header: string, index: number) => ({
                            field: `${index}`,
                            headerName: header,
                            sortable: true,
                            valueFormatter: (params) => {
                              const value = params;
                              if (typeof value === "number") {
                                return formatNumericValue(value);
                              }
                              return value;
                            },
                          }),
                        );

                        return (
                          <div key={chartIndex} className="">
                            <DataGrid
                              columns={columns}
                              rows={rowData}
                              initialState={{
                                pagination: {
                                  paginationModel: {
                                    pageSize: 10,
                                  },
                                },
                              }}
                              disableRowSelectionOnClick
                              pageSizeOptions={[10, 25, 50, 100]}
                              sortingOrder={["asc", "desc"]}
                              sx={{
                                "& .MuiDataGrid-columnHeader": {
                                  backgroundColor: isDarkMode
                                    ? "#343541"
                                    : "white",
                                },
                                "& .MuiDataGrid-filler": {
                                  backgroundColor: isDarkMode
                                    ? "#343541"
                                    : "white",
                                },
                              }}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  {tickersList.length > 0 && !isPreview && (
                    <div>
                      <Button
                        onClick={handleAddToWatchlistBtnClick}
                        className="button-outlined"
                        variant="outlined"
                        size="medium"
                        color="inherit"
                        endIcon={<ChevronDown />}
                      >
                        Add to Watchlist
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleAddToWatchlistBtnClose}
                        MenuListProps={{
                          sx: {
                            width: menuWidth,
                          },
                        }}
                      >
                        {tickersList.map((ticker, index) => (
                          <MenuItem
                            key={index}
                            className="flex gap-x-2"
                            value=""
                            onClick={() => addTickerToWatchlist(index)}
                          >
                            {addedTickers[index]
                              ? (
                                  <CheckFilledCircle className="h-6 w-6" />
                                )
                              : (
                                  <PlusFilledCircle />
                                )}
                            {ticker}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  )}
                </div>
                <div className="mt-1 flex justify-start gap-3">
                  <div className="text-gray-400 flex self-end lg:self-center justify-center lg:justify-start mt-0 -ml-1 empty:h-8">
                    {message.isBot && !message.outputLoading && (
                      <>
                        {onCopy && (
                          <Tooltip title="Copy">
                            <button
                              onClick={() => onCopy(message.output)}
                              className={classNames(
                                "p-1 rounded-md text-token-text-tertiary hover:text-black dark:hover:text-white",
                                {
                                  "md:invisible md:group-hover:visible":
                                    !showActions,
                                },
                              )}
                            >
                              <ContentPasteIcon className="!w-[18px] !h-[18px]" />
                            </button>
                          </Tooltip>
                        )}
                        {onLike && onDislike && (
                          <div className="flex empty:hidden">
                            {onLike && (
                              <Tooltip title="Good response">
                                <button
                                  onClick={() => onLike()}
                                  className={classNames(
                                    "p-1 rounded-md text-token-text-tertiary hover:text-black dark:hover:text-white",
                                    {
                                      "md:invisible md:group-hover:visible":
                                        !showActions,
                                    },
                                  )}
                                >
                                  <ThumbUpOffAltIcon className="!w-[18px] !h-[18px]" />
                                </button>
                              </Tooltip>
                            )}
                            {onDislike && (
                              <Tooltip title="Bad response">
                                <button
                                  onClick={() => onDislike()}
                                  className={classNames(
                                    "p-1 rounded-md text-token-text-tertiary hover:text-black dark:hover:text-white",
                                    {
                                      "md:invisible md:group-hover:visible":
                                        !showActions,
                                    },
                                  )}
                                >
                                  <ThumbDownOffAltIcon className="!w-[18px] !h-[18px]" />
                                </button>
                              </Tooltip>
                            )}
                          </div>
                        )}
                        {/* onRegenerate && showActions &&
                          <Tooltip title="Regenerate">
                              <button onClick={() => onRegenerate()} className={classNames("p-1 rounded-md text-token-text-tertiary hover:text-white", { "md:invisible md:group-hover:visible": !showActions })}>
                                  <ReplayIcon className="!w-[18px] !h-[18px]" />
                              </button>
                          </Tooltip>
                      */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatMessage;
