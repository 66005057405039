import moment from "moment";
import { useEffect, useRef } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Event } from "../assets/interfaces/interfaces";
import { NEWS_LOGOS, NEWS_SOURCES } from "../utils/newsLogos";

dayjs.extend(utc);
export const findLongestName = (user) => {
  const possibleNames = [];

  if (!!user?.firstName && !!user?.lastName) {
    possibleNames.push(`${user?.firstName} ${user?.lastName}`);
  }
  if (user?.display_name) {
    possibleNames.push(user?.display_name);
  }
  if (user?.displayName) {
    possibleNames.push(user?.displayName);
  }

  if (possibleNames.length === 0) {
    return user?.email || "";
  }

  // Find the longest name
  const longestName = possibleNames.reduce((longest, current) => {
    return current.length > longest.length ? current : longest;
  });

  return longestName;
};

export const transformArray = (array, user, setInvalidChat: ((bool?: boolean) => void) = () => { }) => {
  const newArray = [];
  const sortedArray = [...array].sort((a, b) => a.id - b.id);
  sortedArray.forEach((item) => {
    const chatUserId = item.user_id;
    const signedInUserId = user?.userId || "";
    if (chatUserId === signedInUserId) {
      // Create an object for the user query
      newArray.push({
        isBot: false,
        output: item.user_query,
        title: item.session_title,
        date: moment(item.created_at).format("LL"),
        chat_id: `prompt-${item.message_id}`,
      });

      // Create an object for the bot response
      newArray.push({
        date: moment(item.created_at).format("LL"),
        isBot: true,
        outputLoading: false,
        message_id: item.message_id,
        user_query: item.user_query,
        output: item.bot_response,
        chart_data: item?.bot_response_payload?.chart_data, // Assuming these values are default
        chat_id: item.message_id, // Assuming message_id is the chat_id
        chart_flag: JSON.parse(item.chart_output),
        title: item.session_title,
        list_of_tickers: item?.bot_response_payload?.list_of_tickers,
        news_summary: item?.bot_response_payload?.news_summary,
      });
    }
    else {
      // chat does not belong to this user id
      setInvalidChat(true);
    }
  });
  return newArray;
};
export const shareTransform = (array) => {
  const newArray = [];
  const sortedArray = [...array].sort((a, b) => a.id - b.id);
  sortedArray.forEach((item) => {
    // Create an object for the user query
    newArray.push({
      isBot: false,
      output: item.user_query,
      title: item.session_title,
      date: moment(item.created_at).format("LL"),
      chat_id: `prompt-${item.message_id}`,
    });

    // Create an object for the bot response
    newArray.push({
      date: moment(item.created_at).format("LL"),
      isBot: true,
      outputLoading: false,
      output: item.bot_response,
      message_id: item.message_id,
      chart_data: item?.bot_response_payload?.chart_data, // Assuming these values are default
      chat_id: item.message_id, // Assuming message_id is the chat_id
      chart_flag: JSON.parse(item.chart_output),
      title: item.session_title,
      list_of_tickers: item?.bot_response_payload?.list_of_tickers,
      news_summary: item?.bot_response_payload?.news_summary,
    });
  });
  return newArray;
};
type FilteredChatsType = {
  [key: string]: unknown[];
};
export const filterChats = (arr) => {
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
  const sevenDaysAgo = moment().subtract(7, "days").format("YYYY-MM-DD");
  const thirtyDaysAgo = moment().subtract(1, "month").format("YYYY-MM-DD");
  const final: FilteredChatsType = {
    "Today": [],
    "Yesterday": [],
    "Previous 7 Days": [],
    "Previous 30 Days": [],
    "Over 30 Days Ago": [],
  };
  arr.forEach((item) => {
    const date = moment(item.date).format("YYYY-MM-DD");
    if (moment(date).isSame(today)) {
      final["Today"].push(item);
    }
    else if (moment(date).isSame(yesterday)) {
      final["Yesterday"].push(item);
    }
    else if (
      moment(date).isSameOrAfter(sevenDaysAgo)
      && !moment(date).isSame(yesterday)
      && !moment(date).isSame(today)
    ) {
      final["Previous 7 Days"].push(item);
    }
    else if (
      moment(date).isSameOrAfter(thirtyDaysAgo)
      && !moment(date).isSame(yesterday)
      && !moment(date).isSame(today)
      && !moment(date).isSameOrAfter(sevenDaysAgo)
    ) {
      final["Previous 30 Days"].push(item);
    }
    else {
      final["Over 30 Days Ago"].push(item);
    }
  });
  return final;
};
export const themesTV = {
  light: {
    layout: {
      background: { color: "#FFFFFF" }, // A soft light background that's easy on the eyes
      textColor: "rgba(28, 30, 34, 0.9)", // Dark text for high contrast on light background
    },
    grid: {
      vertLines: {
        color: "#ECEFF1", // Light grey for vertical grid lines, subtle and non-distracting
        style: 2, // Dashed lines
      },
      horzLines: {
        color: "#ECEFF1", // Matching horizontal lines with vertical ones
        style: 2, // Dashed lines
      },
    },
    timeScale: {
      borderColor: "#D1D4DC", // A border color that blends well with the layout
      timeVisible: true, // Ensure time is visible
      secondsVisible: false, // Avoid clutter by hiding seconds
    },
    priceScale: {
      borderColor: "#D1D4DC", // Matching the time scale border
      autoScale: true,
    },
    crosshair: {
      mode: 1, // Show crosshair by default
      vertLine: {
        color: "#758696", // A color that stands out but isn't too bold
        style: 0, // Solid line
        width: "1px",
        labelVisible: true,
      },
      horzLine: {
        color: "#758696", // Matching vertical crosshair
        style: 0, // Solid line
        width: "1px",
        labelVisible: true,
      },
    },
  },
  dark: {
    layout: {
      background: { color: "#1E1E1E" }, // A deep dark background for night mode
      textColor: "rgba(216, 222, 233, 0.9)", // Light text for contrast
    },
    grid: {
      vertLines: {
        color: "#2B2B43", // Darker lines for a subtle grid
        style: 2, // Dashed lines for texture
      },
      horzLines: {
        color: "#2B2B43", // Consistent with vertical lines
        style: 2, // Dashed lines to match
      },
    },
    timeScale: {
      borderColor: "#262B41", // A darker border for the timescale, blending with the background
      timeVisible: true, // Ensure time is visible
      secondsVisible: false, // Keep the interface clean
    },
    priceScale: {
      borderColor: "#262B41", // Matching the time scale border for consistency
      autoScale: true,
    },
    crosshair: {
      mode: 1, // Default crosshair visibility
      vertLine: {
        color: "#88CFFA", // A bright blue for visibility against the dark background
        style: 0, // Solid line for clarity
        width: 1,
        labelVisible: true,
      },
      horzLine: {
        color: "#88CFFA", // Matching the vertical line for consistency
        style: 0, // Solid line
        width: 1,
        labelVisible: true,
      },
    },
  },
};

export function processDataForTradingView(ohlcvData: unknown[]) {
  // Convert the data for the candlestick series
  const candlestickData = ohlcvData.map(([time, open, high, low, close]) => ({
    time: time / 1000, // Convert milliseconds to seconds if necessary
    open,
    high,
    low,
    close,
  }));

  // Convert the data for the volume series
  const volumeData = ohlcvData.map(([time, , , , , volume]) => ({
    time: time / 1000, // Convert milliseconds to seconds if necessary
    value: volume,
  }));

  return { candlestickData, volumeData };
}
export const processData = (data: number[][]) => {
  const firstDataPoint = data[0][1]; // Assuming the first element is the starting point
  return data.map(point => [
    point[0], // Keep the datetime value unchanged
    ((point[1] - firstDataPoint) / firstDataPoint) * 100, // Convert to percentage change
  ]);
};

export function useDebounce(value, setter, delay) {
  const previousValueRef = useRef(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (value !== previousValueRef.current) {
        setter(value);
        previousValueRef.current = value;
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, setter]);
}
// formatting - using abbreviations for large numbers and rounding to 2 dp to avoid long dp
export const formatNumericValue = (value: number): string => {
  if (value >= 1_000_000_000_000) {
    return `${(value / 1_000_000_000_000).toFixed(2)}T`;
  }
  else if (value >= 1_000_000_000) {
    return `${(value / 1_000_000_000).toFixed(2)}B`;
  }
  else if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(2)}M`;
  }
  else {
    const decimalPart = value.toString().split(".")[1];
    if (decimalPart && decimalPart.length >= 1) {
      return value.toFixed(2);
    }
  }
  return value.toString();
};

export const parseAnalyticsParams = (params) => {
  const result: Record<string, string> = {};
  for (const key in params) {
    switch (key) {
      case "session_id":
        result.sessionId = params[key];
        break;
      case "user_id":
        result.userId = params[key];
        break;
      default:
        result[key] = params[key];
        break;
    }
  }
  return result;
};

export const getDynamicPathObject = (path, params) => {
  let dynamicPath = path;
  for (const key in params) {
    const value = params[key];
    dynamicPath = dynamicPath.replace(value, `:${key}`);
  }
  return dynamicPath;
};

const reducedToolTypes = {
  get_stock_fundamental_ratios: "get_stock_fundamental_ratios",
  get_stock_financial_statement_by_range: "get_stock_financial_statement_by_range",
  get_stock_financial_statement_by_lastK: "get_stock_financial_statement_by_lastK",
  get_asset_price_info: "get_asset_price_info",
  get_company_info: "get_company_info",
  get_recommendation_analysis_and_sentiment: "get_recommendation_analysis_and_sentiment",
  get_ownership_insiders_info: "get_ownership_insiders_info",
  stock_screener: "stock_screener",
  get_company_earnings_report_by_year: "get_company_earnings_report",
  get_company_earnings_report_by_k: "get_company_earnings_report",
  get_company_earnings_calendar: "get_company_earnings_report",
  format_option_ticker: "format_option_ticker",
  get_relevant_stock: "get_relevant_stock",
  get_option_strategy_recommendation: "get_option",
  get_option_chain_metrics_given_expiry: "get_option",
  get_option_expiry_date: "get_option",
  option_chain_screener: "get_option",
  get_top_unusual_darkflow_stocks: "get_top_unusual_darkflow",
  get_unusual_darkflow_for_ticker: "get_top_unusual_darkflow",
  query_SEC_forms: "query_SEC",
  get_SEC_filing_content: "query_SEC",
  get_SEC_filing_metadata: "query_SEC",
  google_search: "google_search",
  get_stock_related_news: "get_news",
  get_finance_market_news_highlight: "get_news",
  get_upcoming_economic_event: "get_economic_event_and_data",
  get_economic_data: "get_economic_event_and_data",
  get_market_indices_performance: "get_economic_event_and_data",
  plot_price_chart: "plot_chart",
  plot_bar_chart: "plot_chart",
  anything_related_to_TRADEALGO_company: "anything_related_to_TRADEALGO_company",
  undefined: "no_description",
};

const toolMarkers = {
  startStart: "<tool_start>",
  startEnd: "</tool_start>",
  endStart: "<tool_end>",
  endEnd: "</tool_end>",
};

export const getMessageOutputAndLoading = (output?: string): [[string, boolean][], string] => {
  const loadersArray: [string, boolean][] = [];
  if (!output) {
    return [loadersArray, ""];
  }
  let remainingStr = "";
  let maxToolEnd = 0;
  for (let i = 0; i < output.length; i++) {
    const startStartFinishIndex = i + toolMarkers.startStart.length;
    if (output.slice(i, startStartFinishIndex) === toolMarkers.startStart) {
      remainingStr += `${output.slice(maxToolEnd, i)}`;
      let fromHereToEndIndex = startStartFinishIndex;
      toolNameLoop: for (let k = startStartFinishIndex; k < output.length; k++) {
        if (output.slice(k, k + 1) === "`") {
          for (let l = k + 1; l < output.length; l++) {
            if (output.slice(l, l + 1) === "`") {
              const keyword = output.slice(k + 1, l);
              loadersArray.push([reducedToolTypes[keyword] ?? reducedToolTypes["undefined"], false]);
              i = l;
              fromHereToEndIndex = l;
              break toolNameLoop;
            }
          }
        }
      }

      for (let j = fromHereToEndIndex + 1; j < output.length; j++) {
        const startEndIndex = j + toolMarkers.startEnd.length;
        if (output.slice(j, startEndIndex) === toolMarkers.startEnd) {
          maxToolEnd = startEndIndex;
          i = startEndIndex - 1;
          break;
        }
      }
    }

    const endStartFinishIndex = i + toolMarkers.endStart.length;
    if (output.slice(i, endStartFinishIndex) === toolMarkers.endStart) {
      remainingStr += `${output.slice(maxToolEnd, i)}`;
      let fromHereToEndIndex = endStartFinishIndex;
      toolNameLoopEnd: for (let k = endStartFinishIndex; k < output.length; k++) {
        if (output.slice(k, k + 1) === "`") {
          for (let l = k + 1; l < output.length; l++) {
            if (output.slice(l, l + 1) === "`") {
              const keyword = output.slice(k + 1, l);
              const finishedLoader = loadersArray.find(([key, isFinished]) => key === reducedToolTypes[keyword] && isFinished === false);
              if (finishedLoader) {
                finishedLoader[1] = true;
              }
              i = l;
              fromHereToEndIndex = l;
              break toolNameLoopEnd;
            }
          }
        }
      }

      for (let j = fromHereToEndIndex + 1; j < output.length; j++) {
        const endEndIndex = j + toolMarkers.endEnd.length;
        if (output.slice(j, endEndIndex) === toolMarkers.endEnd) {
          maxToolEnd = endEndIndex;
          i = endEndIndex - 1;
          break;
        }
      }
    }
  }

  remainingStr += `${output.slice(maxToolEnd)}`;

  return [loadersArray, remainingStr.trim()];
};

export const getReducedLoadersArray = (loadersArray: [string, boolean][]) => {
  if (!loadersArray.length) return [];

  const result = [loadersArray[0]];
  for (let i = 1; i < loadersArray.length; i++) {
    const curr = loadersArray[i];
    const [key, isLoading] = curr;
    if (key === result.at(-1)?.[0]) {
      if (!isLoading) {
        result[result.length - 1][1] = isLoading;
      }
    }
    else {
      result.push(curr);
    }
  }

  return result;
};

export const numberFormatter = (number, decimalPlaces = 2) => {
  if (isNaN(number)) return "";

  const formatter = customFormatter(decimalPlaces);
  let formattedNumber = formatter.format(number);

  if (decimalPlaces > 2) {
    formattedNumber = formattedNumber.replace(/(\.\d*?)0+$/, "$1");
    if (formattedNumber.endsWith(".")) {
      formattedNumber = formattedNumber.slice(0, -1);
    }
  }

  return formattedNumber;
};

export const currencyFormatter = (number, decimalPlace = 2) => {
  if (isNaN(number)) return "";

  const formatter = customFormatter(decimalPlace, true);
  const formattedNumber = formatter.format(number);

  return formattedNumber;
};

const customFormatter = (decimalPlaces = 2, withCurrency = false) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    ...(
      withCurrency
        ? {
            style: "currency",
            currency: "USD",
          }
        : {}
    ),
  });
};

export const processSparklineData = (data, setSeries) => {
  if (data?.results) {
    const seriesForSparkline = data.results
      .slice(0, 16)
      .map(result => ({
        price: result.c, // closing price
        time: result.t, // timestamp
      }))
      .reverse();

    if (Array.isArray(seriesForSparkline) && seriesForSparkline.length >= 16) {
      setSeries(seriesForSparkline);
    }
    else {
      setSeries("Data unavailable");
    }
  }
  else {
    setSeries("Data unavailable");
  }
};

export const isWithinLastDay = (timestamp: string) => {
  return dayjs(timestamp).isAfter(dayjs().subtract(1, "day"));
};

export const daysSince = (timestamp: string): number => {
  const givenDate = dayjs.utc(timestamp);
  const today = dayjs.utc();

  return Math.ceil(today.diff(givenDate, "day", true));
};

export const getSourceLogo = (newsUrl: string): string | null => {
  try {
    const domain = getDomain(newsUrl);
    return domain ? NEWS_LOGOS[domain] || null : null;
  }
  catch {
    return null;
  }
};

export const getSourceLogos = (events: Event[]) => {
  const logos = new Set<string>();
  events.forEach((event) => {
    const logo = getSourceLogo(event.news_url);
    if (logo) logos.add(logo);
  });
  return Array.from(logos);
};

export const formatTimestamp = (timestamp: string | number): string => {
  return dayjs(timestamp).format("MMM D, YYYY [on] HH:mm");
};

export const getDomain = (url: string): string | null => {
  try {
    return new URL(url).hostname.replace(/^www\./, "");
  }
  catch {
    return null;
  }
};

export const getSourceName = (newsUrl: string): string | null => {
  const domain = getDomain(newsUrl);
  return domain ? NEWS_SOURCES[domain] || "Unknown Source" : null;
};

export const sanitizeTitle = (title: string) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]/g, "");
};
