import { ReactComponent as LogoFilledFull } from "../Icons/LogoFilledFull.svg";
import { Button, ButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="py-12 flex flex-col items-center bg-white dark:bg-background/paper-elevation-1">
      <div className="flex items-center justify-center pb-6">
        <LogoFilledFull className="text-black dark:text-white" />
        <h2 className="text-xl font-semibold ml-3 text-black dark:text-white">TradeGPT</h2>
      </div>
      <ButtonGroup color="inherit" orientation="horizontal" variant="text">
        <Button
          size="medium"
          className="button-link !border-mui-black-12 dark:!border-mui-white-12"
          onClick={() => navigate("/policies/service-terms")}
        >
          Terms & Policies
        </Button>
        <Button
          size="medium"
          className="button-link !border-mui-black-12 dark:!border-mui-white-12"
          onClick={() => navigate("/policies/privacy-policy")}
        >
          Private Policy
        </Button>
        <Button
          size="medium"
          className="button-link"
          onClick={() => navigate("/policies/legal-disclaimer")}
        >
          Legal Disclaimer
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default Footer;
