import { useCallback, useEffect, useState, memo, useRef, useMemo } from "react";
import { ReactComponent as CogIcon } from "../../assets/icons/CogIcon.svg";
import { ReactComponent as SearchStroke } from "../../assets/icons/SearchStroke.svg";
import {
  Badge,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { MODE, PANELS } from "./constants";
import {
  getTickersInWatchlist,
  updateTickerOrder,
  searchTickerResults,
  deleteTickers,
  resetRemoveList,
  updateTickerSequence,
  resetReorderList,
  addTicker,
  setWatchlistDrawerState,
  resetError,
  resetSearchResults,
  fetchTickersData,
} from "../../redux/slices/watchlistSlice";
import { showNotification } from "../../redux/slices/notificationSlice";
import { debounce, isEqual } from "lodash";
import { CrossIcon } from "../Plans/icons/GPTIcons/CrossIcon";
import { useIncrementTrigger } from "./hooks";
import TickerCard from "./Ticker";
import { connectToDXFeed, disconnectFromSocket } from "../../redux/slices/dxFeedSlice";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { useResizable } from "../../hooks/useResizable";
import ResizableIcon from "../Shared/ResizableIcon";
import ExpandedTickerView from "./ExpandedTickerView";
import { useFetchLatestNews } from "../NewsSummarySection/hooks";
import classNames from "classnames";

const Watchlist = memo(
  ({
    isOpen,
    height,
    setShowFullWatchlist,
    toggleHeights,
    isWatchlistExpanded,
    resizeAnimation,
    handlePrompt,
  }: {
    isOpen: boolean;
    height: number;
    setShowFullWatchlist?: (boolean) => void;
    toggleHeights?: (string) => void;
    isWatchlistExpanded: boolean;
    resizeAnimation: string;
    handlePrompt: (msg: string) => void;
  }) => {
    const [mode, setMode] = useState(MODE.VIEW);
    const [searchText, setSearchText] = useState("");
    const [hoveredTickerId, setHoveredTickerId] = useState(null);
    const {
      tickers,
      searchResults,
      isLoading,
      removedIds,
      reorderIds,
      isMutating,
      isHighlightBadge,
      error,
      isAdding,
      watchlistTickersData,
      searchResultsTickersData,
    } = useSelector((state: { watchlist }) => state.watchlist);

    const { data: defaultSummaries } = useFetchLatestNews(null);
    const defaultTickers: string[] = useMemo(
      () => defaultSummaries?.map(item => item.ticker) ?? [],
      [defaultSummaries],
    );

    const isSearchMode = mode === MODE.SEARCH;
    const isSuggestionMode = isSearchMode && searchText === "";

    const tickerData = isSearchMode && !isSuggestionMode ? searchResultsTickersData : watchlistTickersData;
    const user = useSelector((state: { auth }) => state?.auth?.currentUser);
    const tickersOrder
    = reorderIds.length > 0 && tickers.length > 0
      ? reorderIds
      : tickers.map(({ ticker }) => ticker) || [];

    const { updateBadgeCount } = useIncrementTrigger();

    const dispatch = useDispatch<AppDispatch>();

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [visibleTickers, setVisibleTickers] = useState<Set<string>>(new Set());

    const { isHovered, handleMouseEnter, handleMouseLeave } = useResizable();

    useEffect(() => {
      if (error) {
        setShowErrorMessage(true);

        // Clear the notification after 5 seconds
        const timer = setTimeout(() => {
          setShowErrorMessage(false);
          dispatch(resetError());
        }, 5000);

        return () => clearTimeout(timer);
      }
      else {
        setShowErrorMessage(false);
      }
    }, [error, dispatch]);

    useEffect(() => {
      if (showErrorMessage) {
        dispatch(
          showNotification({
            title: "Watchlist Limit Reached",
            message: "Manage your watchlists to add more.",
            severity: "error",
            horizontal: "center",
          }),
        );
      }
    }, [showErrorMessage]);

    useEffect(() => {
      if (isOpen) {
        setMode(MODE.VIEW);
        setSearchText("");
      }
      return () => setMode(MODE.VIEW);
    }, [isOpen]);

    const handleListUpdate = useCallback(async () => {
      const notifyError = () => {
        dispatch(
          showNotification({
            message: "Fail to save watchlist modification",
            severity: "error",
            horizontal: "center",
          }),
        );
      };

      try {
        if (removedIds.length > 0) {
          await dispatch(
            deleteTickers({ ids: removedIds, userId: user.userId }),
          ).unwrap();
          dispatch(resetRemoveList());
        }
        if (reorderIds.length > 0) {
          await dispatch(
            updateTickerSequence({
              payload: reorderIds.map((id, idx) => ({
                ticker: id,
                seq: idx + 1,
              })),
              userId: user.userId,
            }),
          ).unwrap();
          dispatch(resetReorderList());
        }

        if (removedIds.length > 0 || reorderIds.length > 0) {
          await backToViewMode();
        }
      }
      catch (_e) {
        notifyError();
      }
    }, [removedIds, reorderIds, user?.userId, dispatch]);

    const backToViewMode = async () => {
      setMode(MODE.VIEW);
      setSearchText("");
      await dispatch(getTickersInWatchlist({ userId: user?.userId }));
    };

    useEffect(() => {
      setShowFullWatchlist(mode !== MODE.VIEW);
      if (!isSearchMode) {
        dispatch(resetSearchResults());
      }
    }, [mode]);

    const regexForAlphanumericChar = /[a-zA-Z0-9]/;
    const isValidSearchText = text => regexForAlphanumericChar.test(text);

    const debouncedDispatch = useCallback(
      debounce((text) => {
        if (isValidSearchText(text)) {
          dispatch(searchTickerResults(text));
        }
      }, 500),
      [dispatch],
    );
    useEffect(() => {
      if (!searchText) {
        dispatch(resetSearchResults());
        setVisibleTickers(new Set([]));
      }
      if (searchText && isSearchMode) {
        debouncedDispatch(searchText);
      }
    }, [searchText, isSearchMode, debouncedDispatch]);

    const handleDragStart = useCallback(
      (e, id) => {
        if (mode !== MODE.LIST_MANAGEMENT) {
          e.stopPropagation();
          e.preventDefault();
          return;
        }
        e.dataTransfer.setData("text/plain", id);
      },
      [mode],
    );

    const handleDragOver = useCallback(
      (e, id) => {
        if (mode !== MODE.LIST_MANAGEMENT) {
          e.stopPropagation();
          e.preventDefault();
          return;
        }
        e.preventDefault();
        setHoveredTickerId(id);
      },
      [mode],
    );

    const handleDragLeave = useCallback(() => {
      setHoveredTickerId(null);
    }, []);

    const handleDrop = (e, dropId) => {
      if (mode !== MODE.LIST_MANAGEMENT) {
        e.stopPropagation();
        e.preventDefault();
        return;
      }
      const dragId = e.dataTransfer.getData("text");
      const dragIndex = tickersOrder.findIndex(item => item === dragId);
      const dropIndex = tickersOrder.findIndex(item => item === dropId);
      const reorderedItems = [...tickersOrder];
      const [movedItem] = reorderedItems.splice(dragIndex, 1);
      reorderedItems.splice(dropIndex, 0, movedItem);
      setHoveredTickerId(null);
      if (
        isEqual(
          reorderedItems,
          tickers.map(({ ticker }) => ticker),
        )
      ) {
        dispatch(resetReorderList());
      }
      else {
        dispatch(updateTickerOrder(reorderedItems));
      }
    };

    const filteredTickers = useMemo(() => {
      if (isSearchMode) {
        if (isSuggestionMode) {
          return defaultTickers.map(ticker => ({ ticker }));
        }
        return searchResults?.filter(({ ticker }) => !ticker.includes(":")) || [];
      }
      return tickers.filter(({ ticker }) => !removedIds.includes(ticker)) || [];
    }, [isSearchMode, isSuggestionMode, searchResults, tickers, removedIds]);

    const displayedTickers = useMemo(() => {
      if (!isSearchMode) {
        return [...filteredTickers].sort((a, b) => tickersOrder.indexOf(a.ticker) - tickersOrder.indexOf(b.ticker));
      }
      return filteredTickers;
    }, [isSearchMode, filteredTickers, tickersOrder]);

    const onAddTicker = useCallback(async (tickerName) => {
      try {
        await dispatch(addTicker({ userId: user?.userId, ticker: tickerName })).unwrap();
        updateBadgeCount();
      }
      catch (error) {
        console.error("Caught error in component:", error);
        throw error;
      }
    }, [dispatch, user]);

    const textFieldRef = useRef(null);

    const watchlistTickers = useMemo(
      () => tickers.map(ticker => ticker.ticker) || [],
      [tickers],
    );

    const searchResultsTickers = useMemo(
      () => searchResults?.map(ticker => ticker.ticker) || [],
      [searchResults],
    );
    const allTickers = useMemo(
      () => Array.from(new Set([...watchlistTickers, ...searchResultsTickers])),
      [watchlistTickers, searchResultsTickers],
    );

    // SUBSCRIBE TO DX FEED SOCKET
    useEffect(() => {
      if (isOpen) {
        dispatch(connectToDXFeed(Array.from(allTickers)));
      }
    }, [dispatch, allTickers, isOpen]);

    // watchlist cleanup hook
    useEffect(() => {
      return () => {
        dispatch(disconnectFromSocket());
      };
    }, [dispatch, isOpen]);

    const handleTickerVisibility = useCallback((ticker: string, isVisible: boolean) => {
      if (!isSuggestionMode) {
        setVisibleTickers((prev) => {
          const newSet = new Set(prev);
          if (isVisible) {
            newSet.add(ticker);
          }
          else {
            newSet.delete(ticker);
          }
          return newSet;
        });
      }
    }, [isSuggestionMode]);

    const debouncedFetchRef = useRef(debounce(() => {}, 500));

    useEffect(() => {
      const getPrevCloseAndName = async () => {
        const tickersToFetch = isSuggestionMode
          ? Array.from(defaultTickers).filter(
            ticker =>
              !tickerData[ticker])
          : Array.from(visibleTickers).filter(
            ticker =>
              !tickerData[ticker],
          );
        if (tickersToFetch.length === 0) return;
        dispatch(fetchTickersData({ tickersToFetch, isSearchMode: (isSearchMode && !isSuggestionMode) }));
      };
      debouncedFetchRef.current = debounce(getPrevCloseAndName, 500);

      debouncedFetchRef.current();

      return () => {
        debouncedFetchRef.current.cancel();
      };
    }, [visibleTickers, isSuggestionMode]);

    const tokenStatus = useSelector((state: { token }) => state.token.status);
    useEffect(() => {
      if (user && user.userId && tokenStatus === "succeeded") {
        dispatch(getTickersInWatchlist({ userId: user?.userId }));
      }
    }, [user, tokenStatus]);

    const {
      selectedTickerProps,
    } = useSelector((state: { newsSummary }) => state.newsSummary);

    return (
      <>
        {!selectedTickerProps.ticker && (
          <div
            className={classNames(
              "shadow-elevation-custom-1 flex flex-col bg-white dark:bg-background/paper-elevation-4 rounded-lg overflow-hidden relative box-border",
              resizeAnimation,
              {
                "px-4 pb-4": mode === MODE.VIEW,
                "!h-full p-4": mode !== MODE.VIEW,
              },
            )}
            style={{ height: `${height}px`, minHeight: "66px" }}
          >
            <header className="sticky top-0 z-20 flex flex-col gap-y-4">
              <section
                className={`flex justify-between items-center ${
                  mode === MODE.VIEW ? "pt-4" : "py-2"
                } ${
                  mode === MODE.VIEW && !isWatchlistExpanded ? "cursor-pointer" : ""
                }`}
                onClick={() => {
                  if (mode === MODE.VIEW && !isWatchlistExpanded) {
                    toggleHeights(PANELS.WATCHLIST);
                  }
                }}
              >
                <div className="flex gap-x-2 dark:text-white items-center">
                  <TimelineRoundedIcon />
                  <p className="body1 pe-3">Watchlist</p>
                  {tickers.length > 0 && !isAdding && !isLoading && (
                    <Badge
                      variant="standard"
                      badgeContent={
                        mode === MODE.LIST_MANAGEMENT
                          ? tickers.length - removedIds.length
                          : tickers.length
                      }
                      className={classNames({
                        "red-badge": showErrorMessage,
                        "blue-badge": !showErrorMessage && ((mode !== MODE.VIEW && removedIds.length > 0) || isHighlightBadge),
                        "default-badge": !showErrorMessage && !((mode !== MODE.VIEW && removedIds.length > 0) || isHighlightBadge),
                      })}
                    />
                  )}
                  {isAdding && (
                    <CircularProgress disableShrink color="inherit" className="text-tgpt-secondary-main" size={12}></CircularProgress>
                  )}
                </div>
                <div className="flex gap-x-1 items-center justify-end">
                  {mode === MODE.VIEW && (
                    <>
                      <IconButton
                        size="small"
                        className="cross-icon"
                        onClick={() => setMode(MODE.SEARCH)}
                      >
                        <SearchStroke />
                      </IconButton>
                      <IconButton
                        size="small"
                        className="cross-icon"
                        disabled={tickers.length === 0}
                        onClick={() => setMode(MODE.LIST_MANAGEMENT)}
                      >
                        <CogIcon />
                      </IconButton>
                    </>
                  )}
                  {mode !== MODE.LIST_MANAGEMENT
                    ? (
                        <></>
                      )
                    : removedIds.length + reorderIds.length > 0
                      ? (
                          <Button
                            variant="text"
                            color="primary"
                            size="medium"
                            className="button-normalcase"
                            onClick={handleListUpdate}
                            disabled={isMutating}
                          >
                            Save
                          </Button>
                        )
                      : (
                          <Button
                            variant="text"
                            size="medium"
                            color="primary"
                            onClick={backToViewMode}
                            className="button-normalcase"
                          >
                            Save
                          </Button>
                        )}
                  <div className="block sm:hidden">
                    <IconButton
                      size="small"
                      className="cross-icon"
                      onClick={() => dispatch(setWatchlistDrawerState(false))}
                    >
                      <CrossIcon className="fill-current m-1" />
                    </IconButton>
                  </div>
                </div>
              </section>
              {!showErrorMessage && isSearchMode && (
                <section className="flex justify-between gap-x-2">
                  <div className="w-[185px] md:w-[190px]">
                    <TextField
                      placeholder="Search Tickers..."
                      ref={textFieldRef}
                      variant="outlined"
                      size="small"
                      className="rounded-textfield outlined-input labelText"
                      fullWidth
                      value={searchText}
                      autoComplete="off"
                      onFocus={() => setMode(MODE.SEARCH)}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        setMode(MODE.SEARCH);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchStroke className="dark:text-white" />
                            {" "}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {isSearchMode && (
                    <Button
                      variant="text"
                      color="primary"
                      size="medium"
                      className="button-normalcase"
                      onClick={() => {
                        backToViewMode();
                      }}
                    >
                      Back to watchlist
                    </Button>
                  )}
                </section>
              )}
              <section className="flex w-full border-b border-mui-black-12 dark:border-mui-white-12">
                <div
                  className={`caption flex w-full justify-between py-1 ${
                    mode === MODE.LIST_MANAGEMENT
                      ? "px-6"
                      : isSearchMode
                        ? (searchText && "px-4")
                        : "px-2"
                  } text-mui-black-60 dark:text-mui-white-70`}
                >
                  {isSearchMode && !searchText
                    ? (
                        <div className="ps-1 flex justify-between items-center w-full">
                          <p>Popular Search</p>
                          <Tooltip
                            placement="left"
                            title={<p className="text-center text-xs">Showing popular search</p>}
                            classes={{
                              tooltip: "!min-w-[139px]",
                            }}
                          >
                            <IconButton>
                              <ErrorOutlineRoundedIcon className="!w-4 !h-4 dark:fill-mui-white-56" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )
                    : (
                        <>
                          <p>Symbol</p>
                          <p className={`${!isSearchMode ? "" : ""}`}>Sparkline</p>
                          <p>Price</p>
                        </>
                      )}
                </div>
              </section>
            </header>
            {!isLoading
            && (!tickers.length && !isSearchMode) && (
              <section className="flex-grow flex items-center justify-center">
                <div className="flex text-center gap-y-6">
                  <div className="flex flex-col items-center text-center gap-y-6">
                    <QueryStatsRoundedIcon className="!h-[108px] !w-[106px] !fill-secondary-states-focusVisible dark:secondary-states-focusVisible-dark" />
                    <p className="body2 text-mui-black-60 dark:text-mui-white-70">
                      Your watchlist is currently empty. Start adding tickers to stay informed.
                    </p>
                  </div>
                </div>
              </section>
            )}

            <section
              className="flex-grow overflow-y-auto watchlist-scroller box-content me-[-12px] pe-[8px]"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="flex flex-col">
                {isLoading || isMutating || (isSearchMode && isLoading)
                  ? Array.from({ length: 5 }).map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="text"
                      sx={{ fontSize: "3rem", width: "100%" }}
                      animation="wave"
                      className=" dark:bg-white dark:opacity-12"
                    />
                  ))
                  : Array.isArray(displayedTickers) && displayedTickers.length > 0
                    ? displayedTickers.map((ticker, index) => (
                      <div
                        key={ticker.ticker}
                        data-ticker={ticker.ticker}
                        className={classNames(
                          "flex gap-x-2",
                          index !== 0 && "border-t border-mui-black-12 dark:border-mui-white-12",
                          ticker.name && "cursor-pointer",
                        )}
                      >
                        <TickerCard
                          ticker={ticker}
                          onVisibilityChange={handleTickerVisibility}
                          mode={mode}
                          tickerData={tickerData[ticker.ticker] || null}
                          onDragStart={handleDragStart}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                          onAddTicker={onAddTicker}
                          className={`${
                            ticker.ticker === hoveredTickerId
                              ? "border border-mui-primary-blue-light"
                              : ""
                          }`}
                        />
                      </div>
                    ))
                    : null}
              </div>
            </section>
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >

              {isHovered && mode === MODE.VIEW && (
                <ResizableIcon onClick={() => toggleHeights(PANELS.WATCHLIST)} isExpanded={isWatchlistExpanded} />
              )}
            </div>
          </div>
        )}
        {selectedTickerProps.ticker && <ExpandedTickerView handlePrompt={handlePrompt} />}
      </>
    );
  },
);

Watchlist.displayName = "Watchlist";
export default Watchlist;
